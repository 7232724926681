import { log } from 'logger'
import Trans from 'next-translate/Trans'
import { toast } from 'ui-v2/components/Toast'
import { hasAuthorizationProblem } from './checkers'
import { AUTHORIZATION_ERROR_KEY } from './message-keys'
import { BaseError, ProblemHandlerOptions } from './types'

/**
 *
 * @param errors A list of ProblemInterfaces
 * @description handles mutation authorization problems with a standard toast message
 */
export function handleAuthorizationProblem(errors: BaseError[], options: ProblemHandlerOptions) {
  if (hasAuthorizationProblem(errors)) {
    toast.error(<Trans i18nKey={options?.i18nKey ?? AUTHORIZATION_ERROR_KEY} />, {
      toastId: options.toastId,
    })
    log.debug('AuthorizationProblem')
    options?.cb?.()
  }
}
