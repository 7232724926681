
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { ApolloProvider } from '@apollo/client'
import { GoogleAnalytics } from '@next/third-parties/google'
import { Amplify } from 'aws-amplify'
import { RouterHistoryContextProvider } from 'contexts'
import { getTensorFonts } from 'fonts'
import { formatUrl } from 'format'
import 'mapbox-gl/dist/mapbox-gl.css'
import useTranslation from 'next-translate/useTranslation'
import dynamic from 'next/dynamic'
import type { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { RouterProvider as AriaRouterProvider, I18nProvider } from 'react-aria'
import 'react-toastify/dist/ReactToastify.min.css'
import { Provider as BalanceProvider } from 'react-wrap-balancer'
import { getConfig } from 'src/aws-config'
import { LangProvider } from 'src/contexts/LangContext'
import { SentryProvider } from 'src/contexts/SentryContext'
import { useApollo } from 'src/providers/apollo'
import 'stylesheets/baseline.css'
import 'stylesheets/calendar.css'
import { makeLocalizedTheme } from 'theme/src/tensor-cloud-theme'
import { AppPropsWithLayout } from 'typ/src/nextjs'
import { ThemeProvider } from 'ui'
import 'vanilla-extract-config/index.css'
import { isNotNil } from 'typeguards'

const fonts = getTensorFonts()
const analyticsId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID

Amplify.configure({
  ...getConfig(),
  ssr: true,
})

const ToastContainer = dynamic(
  () => import('common/toasts/ToastContainer').then((mod) => mod.ToastContainer),
  { ssr: false } // Disable Server-Side Rendering
)

const NProgress = dynamic(() => import('next-nprogress-bar').then((mod) => mod.PagesProgressBar), {
  ssr: false,
})

const SonnerToastContainer = dynamic(
  () => import('ui-v2/components/Toast').then((mod) => mod.SonnerToastContainer),
  { ssr: false }
)

const ContactSupportModal = dynamic(
  () => import('common-v2/modals/ContactSupportModal').then((mod) => mod.ContactSupportModal),
  { ssr: false }
)

function MyApp({ Component, pageProps }: AppPropsWithLayout): JSX.Element {
  const router = useRouter()
  const getLayout = Component.getLayout ?? ((page) => page)
  const isPlaywright = typeof window === 'undefined' || (window as any)?.isUnderTest
  const client = useApollo(pageProps.initialApolloState, {
    idToken: pageProps.idToken,
  })

  const { lang } = useTranslation()
  const locale = lang === 'ja' ? 'ja-JP' : 'en-US'
  const theme = makeLocalizedTheme(lang, {
    fontFamily: fonts.ibmPlexSans.style.fontFamily,
  })

  const useHref = useCallback((href: LinkProps['href']) => {
    if (typeof href === 'string') {
      return href
    }
    return formatUrl(href)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <I18nProvider locale={locale}>
          <SonnerToastContainer />
          <NProgress
            color={theme.rawColors?.primary || '#aaa'}
            options={{ trickleSpeed: 50, showSpinner: false }}
            shallowRouting
            delay={500}
          />
          <SentryProvider>
            <LangProvider>
              <ContactSupportModal />
              <BalanceProvider>
                <RouterHistoryContextProvider>
                  <AriaRouterProvider
                    navigate={(href, opts) => router.push(href, undefined, opts)}
                    useHref={useHref}
                  >
                    {getLayout(<Component {...pageProps} />)}
                  </AriaRouterProvider>
                  {isNotNil(analyticsId) && !isPlaywright && <GoogleAnalytics gaId={analyticsId} />}
                </RouterHistoryContextProvider>
              </BalanceProvider>
            </LangProvider>
            <ToastContainer id="default" />
          </SentryProvider>
        </I18nProvider>
      </ApolloProvider>
    </ThemeProvider>
  )
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  