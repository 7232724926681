
const hoistNonReactStatics = require('hoist-non-react-statics')

const config = {
  locales: ['ja', 'en'],
  defaultLocale: 'ja',
  loadLocaleFrom: (lang, ns) =>
    import(`../../packages/locales/${lang}/${ns}.json`).then((m) => m.default),
  staticsHoc: hoistNonReactStatics,
  pages: {
    '*': [
      'home',
      'validation',
      'adornments',
      'buttons',
      'common',
      'errors',
      'page-titles',
      'invite',
      'toasts',
      'top-nav',
    ],
    '/': ['auth'],
    '/login': ['auth'],
    '/logout': ['auth'],
    '/you-have-mail': ['auth'],
    '/magic-link': ['auth'],
    '/invitation-login': ['auth'],
    '/workspace-creation': ['auth'],
    '/profile-completion': ['auth'],
    '/ppas/new': ['ppas', 'assets'],
    '/profile': ['auth'],
    '/settings/account': ['settings', 'auth'],
    'rgx:^/assets': ['assets'],
    'rgx:^/assets/solar': ['ppas'],
    'rgx:^/balancing': ['balancing'],
    'rgx:^/balancing-groups': ['balancing-groups'],
    'rgx:^/dashboards/ppas': ['ppas'],
    'rgx:^/forecasting': ['forecasting'],
    'rgx:^/join': ['auth'],
    'rgx:^/analytics': ['analytics'],
    'rgx:^/new-features': ['coming-soon'],
    'rgx:^/notifications': ['notifications'],
    'rgx:^/planning/reports': ['reports', 'assets'],
    'rgx:^/planning/scenarios': ['scenarios'],
    'rgx:^/ppas': ['ppas'],
    'rgx:^/ppas/edit': ['ppas', 'assets'],
    'rgx:^/pwd-confirmation': ['auth'],
    'rgx:^/pwd-recovery': ['auth'],
    'rgx:^/reports/list': ['reports'],
    'rgx:^/settings': ['settings'],
    'rgx:^/spvs/*': ['spvs'],
    'rgx:^/testauth': ['auth'],
    'rgx:^/trading': ['trading'],
    'rgx:^/reporting': ['reporting'],
    'rgx:^/reporting/*': ['reporting'],
    'rgx:^/uploads': ['uploads'],
  },
}

module.exports = config
