import { ApolloCache } from '@apollo/client'
import { Query } from 'generated/tensor-api/generated-types'

/**
 *
 * @param cache The apollo cache object
 * @param fieldNames the fields in ROOT_QUERY to flush
 * @param cleanup whether to perform garbage collection
 */
export const evictRootQueryFields = (
  cache: ApolloCache<any>,
  fieldNames: (keyof Query)[],
  cleanup = true
): void => {
  fieldNames.forEach((fieldName) => {
    cache.evict({
      id: 'ROOT_QUERY',
      fieldName,
    })
  })

  if (cleanup) cache.gc()
}
