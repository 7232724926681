import useTranslation from 'next-translate/useTranslation'
import { useCallback } from 'react'
import { getDocsLink } from './get-docs-link'

/**
 *
 * @param docsHref a docs.tensorenergy.jp section as in "/reference/assets/foo/baz"
 * @returns a function that takes an additional url substring to add to the docsHref parameter and returns a valid docs link
 */
export const useGetDocsLink = (docsHref: string) => {
  const { lang } = useTranslation()

  const getDocsSettingsLink = useCallback(
    (section = '') => getDocsLink(`${docsHref}${section}`, lang),
    [docsHref, lang]
  )

  return getDocsSettingsLink
}
