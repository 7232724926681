import { FC } from 'react'
import { TestProps } from 'typ'
import { BaseIconWrapper } from '../common/BaseIconWrapper'
import { SvgIconProps } from './types'

export type CloseIconProps = SvgIconProps & TestProps

export const CloseIcon: FC<CloseIconProps> = ({ color = 'inherit', size = 'md', ...props }) => (
  <BaseIconWrapper {...props} data-testid="close-icon" color={color} size={size}>
    <path
      d="M2.96967 2.96967C3.26256 2.67678 3.73744 2.67678 4.03033 2.96967L8 6.93934L11.9697 2.96967C12.2626 2.67678 12.7374 2.67678 13.0303 2.96967C13.3232 3.26256 13.3232 3.73744 13.0303 4.03033L9.06066 8L13.0303 11.9697C13.3232 12.2626 13.3232 12.7374 13.0303 13.0303C12.7374 13.3232 12.2626 13.3232 11.9697 13.0303L8 9.06066L4.03033 13.0303C3.73744 13.3232 3.26256 13.3232 2.96967 13.0303C2.67678 12.7374 2.67678 12.2626 2.96967 11.9697L6.93934 8L2.96967 4.03033C2.67678 3.73744 2.67678 3.26256 2.96967 2.96967Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="inherit"
      stroke="transparent"
    />
  </BaseIconWrapper>
)
