import { log } from 'logger'
import Trans from 'next-translate/Trans'
import { toast } from 'ui-v2/components/Toast'
import { hasResourceDoesNotExistProblem } from './checkers'
import { RESOURCE_DOES_NOT_EXIST_KEY } from './message-keys'
import { BaseError, ProblemHandlerOptions } from './types'

/**
 *
 * @param errors A list of ProblemInterfaces
 * @description handles mutation deleted resource problems with a standard toast message
 */
export const handleResourceDoesNotExistProblem = (
  errors: BaseError[],
  options: ProblemHandlerOptions
) => {
  if (hasResourceDoesNotExistProblem(errors)) {
    toast.error(<Trans i18nKey={options.i18nKey ?? RESOURCE_DOES_NOT_EXIST_KEY} />, {
      toastId: options.toastId,
    })
    options?.cb?.()
    log.debug('ResourceDoesNotExistProblem')
  }
}
