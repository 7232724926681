import { SpvsTableField } from '../slices'

export const tensorCloudMigrations = [
  // MIGRATION FROM VERSION 0 TO 1
  (state: any) => state,
  // MIGRATION FROM VERSION 1 TO 2
  (state: any) => {
    const balancingGroupListDefaultCols: string[] = [
      'bgCode',
      'name',
      'gridZone',
      'totalAssets',
      'updatedAt',
    ]
    const reportsListDefaultCols: string[] = ['name', 'scope', 'createdAt', 'author']

    const solarAssetsDefaultCols: string[] = [
      'name',
      'readableId',
      'acCapacity',
      'locationName',
      'simulationStatus',
      'gridZone',
    ]

    const ppaDefaultCols: string[] = [
      'readableId',
      'name',
      'type',
      'capacity',
      'status',
      'startDate',
    ]

    const spvsDefaultCols: SpvsTableField[] = [
      'readableId',
      'jaName',
      'enName',
      'dcCapacity',
      'totalAssets',
      'updatedAt',
      'createdAt',
    ]

    return {
      ...state,
      balancingGroupListLayout: {
        ...state.balancingGroupsListLayout,
        displayedCols: balancingGroupListDefaultCols,
      },
      reportsListLayout: {
        ...state.reportsListLayout,
        displayedCols: reportsListDefaultCols,
      },
      solarAssetsLayout: {
        ...state.solarAssetsLayout,
        displayedCols: solarAssetsDefaultCols,
      },
      ppasListLayout: {
        ...state.ppasListLayout,
        displayedCols: ppaDefaultCols,
      },
      spvsListLayout: {
        ...state.spvsListLayout,
        displayedCols: spvsDefaultCols,
      },
    }
  },
  // MIGRATION FROM VERSION 2 TO 3
  (state: any) => ({
    ...state,
    solarAssetsLayout: {
      ...state.solarAssetsLayout,
      displayedCols: state.solarAssetsLayout.displayedCols.filter((el: any) => el !== 'investor'),
    },
  }),
  // MIGRATION FROM VERSION 3 TO 4
  (state: any) => ({
    ...state,
    solarAssetActiveTab: 'project',
  }),
  // MIGRATION FROM VERSION 4 TO 5
  (state: any) => ({
    ...state,
    settingsStakeholdersActiveTab:
      state.settingsStakeholdersActiveTab === 'investors'
        ? 'offtakers'
        : state.settingsStakeholdersActiveTab,
  }),
  // MIGRATION FROM VERSION 5 TO 6
  (state: any) => ({
    ...state,
  }),
  // MIGRATION FROM VERSION 6 TO 7
  (state: any) => ({
    ...state,
    balancingGroupsListLayout: {
      sortParam: 'createdAt',
      sortAscending: false,
      displayedCols: ['bgCode', 'name', 'gridZone', 'totalAssets', 'updatedAt', 'createdAt'],
    },
  }),
  // MIGRATION FROM VERSION 7 TO 8
  (state: any) => state,
  // MIGRATION FROM VERSION 8 TO 9
  (state: any) => ({
    ...state,
    spvsListLayout: {
      sortParam: 'createdAt',
      direction: 'descending',
      displayedCols: [
        'readableId',
        'jaName',
        'enName',
        'dcCapacity',
        'totalAssets',
        'updatedAt',
        'createdAt',
      ],
    },
    scenariosListLayout: {
      sortParam: 'createdAt',
      direction: 'descending',
      displayedCols: ['readableId', 'link', 'description', 'updatedAt', 'createdAt'],
    },
    ppasListLayout: {
      sortParam: 'createdAt',
      direction: 'descending',
      displayedCols: ['readableId', 'link', 'type', 'capacity', 'status', 'startDate'],
    },
    reportsListLayout: {
      sortParam: 'createdAt',
      direction: 'descending',
      displayedCols: ['readableId', 'link', 'assets', 'ppas', 'createdAt', 'author'],
    },
    solarAssetsLayout: {
      sortParam: 'createdAt',
      direction: 'descending',
      displayedCols: [
        'link',
        'readableId',
        'acCapacity',
        'locationName',
        'simulationStatus',
        'gridZone',
      ],
    },
    balancingGroupsListLayout: {
      sortParam: 'createdAt',
      direction: 'descending',
      displayedCols: ['bgCode', 'link', 'gridZone', 'totalAssets', 'updatedAt', 'createdAt'],
    },
  }),
  // MIGRATION FROM VERSION 9 TO 10
  (state: any) => ({
    ...state,
    solarAssetsLayout: {
      sortParam: 'createdAt',
      direction: 'descending',
      displayedCols: [
        'link',
        'readableId',
        'dcCapacity',
        'locationName',
        'simulationStatus',
        'gridZone',
      ],
    },
  }),
  // MIGRATION FROM VERSION 10 TO 11
  (state: any) => ({
    ...state,
    spvsListLayout: {
      sortDescriptor: {
        column: state.spvsListLayout.sortParam,
        direction: state.spvsListLayout.direction,
      },
      ...state.spvsListLayout,
    },
    scenariosListLayout: {
      sortDescriptor: {
        column: state.scenariosListLayout.sortParam,
        direction: state.scenariosListLayout.direction,
      },
      ...state.scenariosListLayout,
    },
    ppasListLayout: {
      sortDescriptor: {
        column: state.ppasListLayout.sortParam,
        direction: state.ppasListLayout.direction,
      },
      ...state.ppasListLayout,
    },
    solarAssetsLayout: {
      sortDescriptor: {
        column: state.solarAssetsLayout.sortParam,
        direction: state.solarAssetsLayout.direction,
      },
      ...state.solarAssetsLayout,
    },
    reportsListLayout: {
      sortDescriptor: {
        column: state.reportsListLayout.sortParam,
        direction: state.reportsListLayout.direction,
      },
      ...state.reportsListLayout,
    },
    balancingGroupsListLayout: {
      sortDescriptor: {
        column: state.balancingGroupsListLayout.sortParam,
        direction: state.balancingGroupsListLayout.direction,
      },
      ...state.balancingGroupsListLayout,
    },
    baselinesListLayout: {
      sortDescriptor: {
        column: state.baselinesListLayout.sortParam,
        direction: state.baselinesListLayout.direction,
      },
      ...state.baselinesListLayout,
    },
  }),
  // MIGRATION FROM VERSION 11 TO 12
  (state: any) => ({
    ...state,
    preferredForecastsLength:
      state.preferredForecastsLength === '14' ? '13' : state.preferredForecastsLength,
  }),
]
