import { ButtonIconProps } from '../types'

export const getButtonLayout = ({ LeftIcon, RightIcon }: ButtonIconProps) =>
  !LeftIcon && !RightIcon
    ? 'default'
    : LeftIcon && RightIcon
      ? 'bothIcons'
      : LeftIcon
        ? 'leftIcon'
        : 'rightIcon'
