import { useCallback, useState } from 'react'
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect'
import { useLayoutWindowEvent } from './useLayoutWindowEvent'

const eventListerOptions = {
  passive: true,
}

export function useViewportSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  })

  const setSize = useCallback(() => {
    setWindowSize({
      width: window.innerWidth || 0,
      height: window.innerHeight || 0,
    })
  }, [])

  useLayoutWindowEvent('resize', setSize, eventListerOptions)
  useLayoutWindowEvent('orientationchange', setSize, eventListerOptions)
  useIsomorphicLayoutEffect(setSize, [setSize])

  return windowSize
}
