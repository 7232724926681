import { useCallback, useEffect, useRef } from 'react'

export function useEventCallback<TCallback extends (...args: any[]) => any>(
  fn?: TCallback | null
): TCallback {
  const ref = useRef(fn)
  useEffect(() => {
    ref.current = fn
  }, [fn])

  return useCallback((...args: any[]) => ref.current?.(...args), []) as any
}
