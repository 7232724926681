import { StoreSlice } from '../types'

export interface LastUpdateSlice {
  dayAheadLastUpdated: Date | null
  intradayLastUpdated: Date | null
  imbalanceLastUpdated: Date | null
  capacityLastUpdated: Date | null
  certificatesLastUpdated: Date | null
  ancillaryLastUpdated: Date | null
  generationLastUpdated: Date | null
  consumptionLastUpdated: Date | null
  setDayAheadLastUpdated: (lastUpdate: Date) => void
  setIntradayLastUpdated: (lastUpdate: Date) => void
  setImbalanceLastUpdated: (lastUpdate: Date) => void
  setCapacityLastUpdated: (lastUpdate: Date) => void
  setCertificatesLastUpdated: (lastUpdate: Date) => void
  setAncillaryLastUpdated: (lastUpdate: Date) => void
  setGenerationLastUpdated: (lastUpdate: Date) => void
  setConsumptionLastUpdated: (lastUpdate: Date) => void
}
export const createLastUpdateSlice: StoreSlice<LastUpdateSlice> = (set, _get) => ({
  dayAheadLastUpdated: null,
  intradayLastUpdated: null,
  imbalanceLastUpdated: null,
  capacityLastUpdated: null,
  certificatesLastUpdated: null,
  ancillaryLastUpdated: null,
  generationLastUpdated: null,
  consumptionLastUpdated: null,
  setDayAheadLastUpdated: (lastUpdate: Date) => set({ dayAheadLastUpdated: lastUpdate }),
  setIntradayLastUpdated: (lastUpdate: Date) => set({ intradayLastUpdated: lastUpdate }),
  setImbalanceLastUpdated: (lastUpdate: Date) => set({ imbalanceLastUpdated: lastUpdate }),
  setCapacityLastUpdated: (lastUpdate: Date) => set({ capacityLastUpdated: lastUpdate }),
  setCertificatesLastUpdated: (lastUpdate: Date) => set({ certificatesLastUpdated: lastUpdate }),
  setAncillaryLastUpdated: (lastUpdate: Date) => set({ ancillaryLastUpdated: lastUpdate }),
  setGenerationLastUpdated: (lastUpdate: Date) => set({ generationLastUpdated: lastUpdate }),
  setConsumptionLastUpdated: (lastUpdate: Date) => set({ consumptionLastUpdated: lastUpdate }),
})
