import { ApolloCache, DocumentNode, Reference, StoreObject } from '@apollo/client'
import { min } from 'array'
import { PaginationInfo, Query } from 'generated/tensor-api/generated-types'
import { IdEntity } from 'typ'

type OverwriteListDataCacheItemsOptions = {
  field: keyof Query
  records: IdEntity[]
  pageInfo?: PaginationInfo
  fragment: DocumentNode
}
export function overwriteListDataCacheItems(
  cache: ApolloCache<any>,
  { field, records, fragment, pageInfo }: OverwriteListDataCacheItemsOptions
) {
  cache.modify({
    fields: {
      [field]: (existing: any) => {
        const refs = records.map((record) =>
          cache.writeFragment({
            fragment,
            data: record,
          })
        )
        return {
          ...existing,
          pageInfo: pageInfo ?? existing.pageInfo,
          records: refs,
        }
      },
    },
  })
}

type UpdateListDataCacheItemOptions<T extends StoreObject> = {
  field: keyof Query
  record: IdEntity
  fragment: DocumentNode
  update: Partial<T>
}
export function updateListDataCacheItem<T extends StoreObject>(
  cache: ApolloCache<any>,
  { field, record, fragment, update }: UpdateListDataCacheItemOptions<T>
) {
  const updatedRecord = cache.identify(record)
  cache.modify({
    fields: {
      [field]: (existing: any) => {
        const updateRef = cache.updateFragment(
          {
            id: updatedRecord,
            fragment,
          },
          (res) => ({
            ...res,
            ...update,
          })
        )
        const updatedRecords = existing.records.map((r: any) => {
          if (r.id === updatedRecord) {
            return updateRef
          }
          return r
        })

        return {
          ...existing,
          records: updatedRecords,
        }
      },
    },
  })
}

type AddListDataCacheItemOptions = {
  field: keyof Query
  record: IdEntity
  fragment: DocumentNode
  storeFieldNameMustInclude?: string
}

export function addListDataCacheItem(
  cache: ApolloCache<any>,
  { field, record, fragment, storeFieldNameMustInclude }: AddListDataCacheItemOptions
) {
  if (!record) return
  cache.modify({
    fields: {
      [field]: (existing: any, { readField, storeFieldName }) => {
        if (storeFieldNameMustInclude && !storeFieldName.includes(storeFieldNameMustInclude)) {
          return existing
        }
        const newRef = cache.writeFragment({
          data: record,
          fragment,
        })
        const newRefId = readField('id', newRef)
        if (
          !newRef ||
          existing.records.some((ref: Reference) => readField('id', ref) === newRefId)
        ) {
          return existing.records
        }
        const newTotalRecords = existing.pageInfo.totalRecords + 1
        const newTotalPages = Math.ceil(newTotalRecords / existing.pageInfo.perPage)
        return {
          ...existing,
          pageInfo: {
            ...existing.pageInfo,
            totalRecords: newTotalRecords,
            isEmpty: newTotalRecords <= 0,
            totalPages: newTotalPages,
            page: min([existing.pageInfo.page, newTotalPages]),
          },
          records: [...existing.records, newRef],
        }
      },
    },
  })
}

type DeleteListDataCacheItemOptions = {
  field: keyof Query
  record: IdEntity
}

export function deleteListDataCacheItem(
  cache: ApolloCache<any>,
  { field, record }: DeleteListDataCacheItemOptions
) {
  if (!record) return
  cache.modify({
    fields: {
      // biome-ignore lint/style/useDefaultParameterLast: Not sure if this breaks the code
      [field]: (existing: any = [], { readField }) => {
        const filteredRecords = existing.records.filter(
          (r: any) => record.id !== readField('id', r)
        )
        const newTotalRecords = existing.pageInfo.totalRecords - 1
        const newTotalPages = Math.ceil(newTotalRecords / existing.pageInfo.perPage)
        return {
          ...existing,
          pageInfo: {
            ...existing.pageInfo,
            totalRecords: newTotalRecords,
            isEmpty: newTotalRecords <= 0,
            totalPages: newTotalPages,
            page: min([existing.pageInfo.page, newTotalPages]),
          },
          records: filteredRecords,
        }
      },
    },
  })
}
