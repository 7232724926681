import { CheckCircleIcon } from 'icons/CheckCircleIcon'
import { CloseIcon } from 'icons/CloseIcon'
import { ErrorIcon } from 'icons/ErrorIcon'
import { InfoIcon } from 'icons/InfoIcon'
import { ReactNode, forwardRef, useEffect, useState } from 'react'
import { toast } from 'sonner'
import { TestProps } from 'typ'
import { isDefined } from 'typeguards'
import { Button } from '../button'
import { Flex } from '../flex'
import { IconButton } from '../icon-button'
import { Text } from '../text'
import {
  contentsCx,
  fadeOutAnimation,
  iconCx,
  pulseAnimation,
  wiggleAnimation,
  wrapperCx,
} from './Toast.css'
import { Variant } from './types'

const iconMap = {
  success: <CheckCircleIcon color="baseColorsSuccess9" />,
  info: <InfoIcon color="baseColorsPrimary9" />,
  default: false,
  error: <ErrorIcon color="baseColorsDanger9" />,
} as const

export interface ToastContentsProps extends TestProps {
  buttonProps?: {
    label: ReactNode
    onPress: VoidFunction
  }
  variant: Variant
  title?: ReactNode
  description: ReactNode
  id: string
  lastUpdated?: number
}

export const ToastContents = forwardRef<HTMLDivElement, ToastContentsProps>(
  ({ variant, description, title, buttonProps, id, lastUpdated }, _ref) => {
    const [isUpdating, setIsUpdating] = useState(false)
    const layout = isDefined(title) ? 'multiline' : 'inline'
    const [isClosed, setIsClosed] = useState(false)
    const [hasEntered, setHasEntered] = useState(false)

    const Icon = iconMap[variant]
    const handleClose = async () => {
      setIsClosed(true)
    }

    const handleActionPress = () => {
      if (buttonProps) {
        buttonProps.onPress()
      }
      handleClose()
    }
    const handleAnimationEnd = (e: React.AnimationEvent) => {
      if (e.animationName === wiggleAnimation) {
        setHasEntered(true)
      }
      if (e.animationName === fadeOutAnimation) {
        toast.dismiss(id)
      }
      if (e.animationName === pulseAnimation) {
        setIsUpdating(false)
      }
    }

    useEffect(() => {
      if (lastUpdated) {
        setIsUpdating(true)
      }
    }, [lastUpdated])

    const showActionButton = buttonProps?.label

    return (
      <div
        className={wrapperCx({ isClosed, hasEntered, isRefreshing: isUpdating, layout })}
        onAnimationEnd={handleAnimationEnd}
        id={id}
        data-testid={`toast-${variant}`}
        data-is-closed={isClosed}
      >
        <Flex
          className={contentsCx({
            layout,
          })}
        >
          {Icon && <div className={iconCx}>{iconMap[variant]}</div>}
          <Flex
            direction={layout === 'inline' ? 'row' : 'column'}
            rowGap="500"
            columnGap="600"
            align={layout === 'inline' ? 'center' : 'flex-start'}
          >
            <Flex direction="column" gap="200">
              {title && <Text variant="labelMd">{title}</Text>}
              <Text variant="paragraphMd">{description}</Text>
            </Flex>
            {showActionButton && (
              <Button variant="ghost" color="primary" size="sm" onPress={handleActionPress}>
                {buttonProps.label}
              </Button>
            )}
          </Flex>
        </Flex>
        <IconButton
          Icon={CloseIcon}
          onPress={handleClose}
          variant="ghost"
          color="neutral"
          size="sm"
          shape="square"
          label="Close"
          tooltipProps={{
            isOpen: false,
          }}
        />
      </div>
    )
  }
)

ToastContents.displayName = 'ToastContents'
