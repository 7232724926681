import { maxExpiry } from 'datetime'
import { log } from 'logger'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { I18nProvider } from 'react-aria'
import { useCookies } from 'react-cookie'
import { Locale } from 'typ'
import { isIncluded, isNotIncluded } from 'typeguards'

export enum LangCookies {
  NEXT_LOCALE = 'NEXT_LOCALE',
}

export const getLangContext = (langSettingsUrls: string[], disableAutoRedirect?: boolean) => {
  type LangContextOutput = {
    lang: string
  }

  const LangContext = createContext<LangContextOutput | undefined>(undefined)

  type LangProviderProps = object

  const LangProvider = ({ children }: PropsWithChildren<LangProviderProps>): JSX.Element => {
    const [isFirstRender, setIsFirstRender] = useState(true)
    const router = useRouter()
    const [cookies, setCookie] = useCookies([LangCookies.NEXT_LOCALE])
    const { lang } = useTranslation()

    const setCookiesToLang = useCallback(
      (target: Locale) => {
        setCookie(LangCookies.NEXT_LOCALE, target, {
          path: '/',
          expires: maxExpiry,
        })
      },
      [setCookie]
    )

    const setupE2EDefaultLanguage = useCallback(() => {
      if ((window as any).isUnderTest && !cookies.NEXT_LOCALE) {
        setCookiesToLang('en')
      }
    }, [cookies.NEXT_LOCALE, setCookiesToLang])

    const setLanguageBasedOnNavigatorPreferences = useCallback(() => {
      const preferred = navigator.language
      if (/^en\b/.test(preferred)) {
        log.debug('Setting default language to english')
        setCookiesToLang('en')
        router.replace(router.asPath, undefined, { locale: 'en' })
      } else {
        setCookiesToLang('ja')
      }
    }, [router, setCookiesToLang])

    useEffect(() => {
      const cookieLocale = cookies.NEXT_LOCALE
      const isInvalidLocale = Boolean(cookieLocale) && isNotIncluded(cookieLocale, ['en', 'ja'])
      // FIX OLD BROKEN COOKIES
      if (isInvalidLocale) {
        setCookiesToLang('ja')
        return
      }
      if (isFirstRender) {
        setIsFirstRender(false)
        setupE2EDefaultLanguage()
      }
      // SKIP WHAT FOLLOWS IF WE ARE IN A LANGUAGE SETTINGS PAGE
      if (!isFirstRender && isIncluded(router.pathname, langSettingsUrls)) {
        return
      }
      if (!disableAutoRedirect && !cookieLocale) {
        setLanguageBasedOnNavigatorPreferences()
      } else if (isFirstRender && !isInvalidLocale && cookieLocale !== lang) {
        router.replace(router.asPath, undefined, {
          locale: cookieLocale,
        })
      }
    }, [
      langSettingsUrls,
      disableAutoRedirect,
      router,
      cookies.NEXT_LOCALE,
      lang,
      isFirstRender,
      router.pathname,
      setupE2EDefaultLanguage,
      setLanguageBasedOnNavigatorPreferences,
      setCookiesToLang,
    ])

    return (
      <LangContext.Provider value={{ lang }}>
        <I18nProvider locale={lang}>{children}</I18nProvider>
      </LangContext.Provider>
    )
  }

  const useLang = (): LangContextOutput => {
    const context = useContext(LangContext)
    if (context === undefined) {
      throw new Error('useLang must be used within a LangProvider')
    }
    return context
  }

  return { LangProvider, useLang }
}
