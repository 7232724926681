import { FC } from 'react'
import { TestProps } from 'typ'
import { BaseIconWrapper } from '../common/BaseIconWrapper'
import { SvgIconProps } from './types'

export type ErrorIconProps = SvgIconProps & TestProps

export const ErrorIcon: FC<ErrorIconProps> = ({ color = 'inherit', size = 'md', ...props }) => (
  <BaseIconWrapper {...props} data-testid="error-icon" color={color} size={size}>
    <path
      d="M8.00002 1.5C4.13402 1.5 1 4.634 1 8.49997C1 12.3659 4.13402 15.5 8.00002 15.5C11.866 15.5 15 12.3659 15 8.49997C15 4.634 11.866 1.5 8.00002 1.5ZM2.0041 8.49997C2.0041 5.18855 4.68857 2.5041 8.00002 2.5041C11.3114 2.5041 13.9959 5.18855 13.9959 8.49997C13.9959 11.8114 11.3114 14.4959 8.00002 14.4959C4.68857 14.4959 2.0041 11.8114 2.0041 8.49997Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="inherit"
      stroke="transparent"
    />
    <path
      d="M7.12504 11.125C7.12504 10.6418 7.51679 10.25 8.00004 10.25C8.4833 10.25 8.87505 10.6418 8.87505 11.125C8.87505 11.6082 8.4833 12 8.00004 12C7.51679 12 7.12504 11.6082 7.12504 11.125Z"
      fill="inherit"
      stroke="transparent"
    />
    <path
      d="M7.12504 5.875C7.12504 5.39175 7.51679 5 8.00004 5C8.4833 5 8.87505 5.39175 8.87505 5.875V8.5C8.87505 8.98325 8.4833 9.375 8.00004 9.375C7.51679 9.375 7.12504 8.98325 7.12504 8.5V5.875Z"
      fill="inherit"
      stroke="transparent"
    />
  </BaseIconWrapper>
)
