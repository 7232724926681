import { noSelect } from './utils'

const lg: any = {
  height: '2.625rem',
  padding: '0rem 1.375rem',
  fontSize: 3,
  borderRadius: 2,
}
const md: any = {
  height: '2.1875rem',
  fontSize: 2,
  padding: '0rem 1.125rem',
  borderRadius: 2,
}
const sm: any = {
  height: '1.875rem',
  padding: '0rem .875rem',
  fontSize: 1,
  borderRadius: 2,
}

const baseDisabledStyles: any = {
  bg: 'gray1',
  color: 'gray3',
  fill: 'gray3',
  stroke: 'gray3',
  '&:hover': {
    bg: 'gray1',
    color: 'gray3',
    cursor: 'not-allowed',
  },
}

export const baseBtnStyles: any = {
  display: 'grid',
  placeItems: 'center',
  lineHeight: 1.5,
  whiteSpace: 'nowrap',
  minWidth: 'max-content',
  fontFamily: 'body',
  fontWeight: 'medium',
  cursor: 'pointer',
  transition: 'background .15s',
  border: 0,
  outline: 'none',
  ...noSelect,
  '&:disabled': {
    ...baseDisabledStyles,
  },
  '&:focus-visible': {
    boxShadow: 'focusRing',
  },
}
const iconBtnBaseStyles: any = {
  display: 'grid',
  cursor: 'pointer',
  transition: 'background .15s',
  placeItems: 'center',
  padding: '1px',
  margin: 0,
  height: '1.5rem',
  width: '1.5rem',
  minHeight: '1.5rem',
  minWidth: '1.5rem',
  borderRadius: 'max',
  outline: 'none',
  border: 'none',
  '&:focus-visible': {
    boxShadow: 'focusRing',
  },
  '&:disabled': {
    ...baseDisabledStyles,
  },
}

const basePrimaryStyles: any = {
  bg: 'primary',
  color: 'white',
  fill: 'white',
  stroke: 'white',
  transition: 'color .15s, border-color .15s, background .15s',
  '&:hover': {
    bg: 'primaryShade2',
  },
}

const baseSecondaryStyles: any = {
  bg: 'primaryTint1plus',
  color: 'primary',
  fill: 'primary',
  stroke: 'primary',
  transition: 'color .15s, border-color .15s, background .15s',
  '&:hover': {
    bg: 'primaryTint2',
  },
}
const baseTertiaryStyles: any = {
  ...baseBtnStyles,
  bg: 'transparent',
  color: 'primary',
  fill: 'primary',
  stroke: 'primary',
  '&:hover': {
    bg: 'steel4',
    color: 'primary',
    fill: 'primary',
    stroke: 'primary',
  },
}
const baseDangerStyles: any = {
  ...baseBtnStyles,
  ...basePrimaryStyles,
  bg: 'dangerTint1',
  color: 'danger',
  fill: 'danger',
  stroke: 'danger',
  '&:hover': {
    bg: 'dangerTint2',
  },
}

const baseGrayStyles: any = {
  ...baseBtnStyles,
  ...baseSecondaryStyles,
  color: 'textGray',
  borderColor: 'steel2',
  '&:hover': {
    color: 'text',
    border: '1px solid',
    borderColor: 'textGray',
  },
}
const baseTextStyles: any = {
  display: 'inline',
  lineHeight: 'inherit',
  bg: 'transparent',
  borderRadius: '2px',
  padding: 0,
  margin: 0,
  border: 'none',
  fontSize: 'inherit',
  fontFamily: 'inherit',
  color: 'primary',
  fill: 'primary',
  height: 'max-content',
  '&:hover': {
    textDecoration: 'underline',
  },

  '&:disabled': {
    color: 'gray3',
    fill: 'gray3',
    stroke: 'gray3',
    cursor: 'not-allowed',
    '&:hover': {
      color: 'gray3',
      fill: 'gray3',
      stroke: 'gray3',
      textDecoration: 'none',
    },
  },
}
export const buttons: Record<string, any> = {
  'primary-md': {
    ...md,
    ...baseBtnStyles,
    ...basePrimaryStyles,
  },
  'primary-sm': {
    ...sm,
    ...baseBtnStyles,
    ...basePrimaryStyles,
  },
  'primary-lg': {
    ...lg,
    ...baseBtnStyles,
    ...basePrimaryStyles,
  },
  'secondary-md': {
    ...md,
    ...baseBtnStyles,
    ...baseSecondaryStyles,
  },
  'secondary-sm': {
    ...sm,
    ...baseBtnStyles,
    ...baseSecondaryStyles,
  },
  'secondary-lg': {
    ...lg,
    ...baseBtnStyles,
    ...baseSecondaryStyles,
  },
  'danger-md': {
    ...baseDangerStyles,
    ...md,
  },
  'danger-sm': {
    ...baseDangerStyles,
    ...sm,
  },
  'danger-lg': {
    ...baseDangerStyles,
    ...lg,
  },
  'tertiary-md': {
    ...baseTertiaryStyles,
    ...md,
  },
  'tertiary-sm': {
    ...baseTertiaryStyles,
    ...sm,
  },
  'tertiary-lg': {
    ...baseTertiaryStyles,
    ...lg,
  },
  'gray-md': {
    ...baseGrayStyles,
    ...md,
  },
  'gray-sm': {
    ...baseGrayStyles,
    ...sm,
  },
  'gray-lg': {
    ...baseGrayStyles,
    ...lg,
  },
  'icon-primary': {
    ...iconBtnBaseStyles,
    ...basePrimaryStyles,
  },
  'icon-secondary': {
    ...iconBtnBaseStyles,
    ...baseSecondaryStyles,
    padding: 0,
  },
  close: {
    color: 'textGray',
    cursor: 'pointer',
    outline: 'none',
    '&:hover': { color: 'primary' },
    '&:focus-visible': {
      boxShadow: 'focusRing',
    },
    svg: {
      height: 20,
      width: 20,
    },
  },
  text: {
    ...baseBtnStyles,
    ...baseTextStyles,
  },
  'text-md': {
    ...baseBtnStyles,
    ...baseTextStyles,
    fontSize: md.fontSize,
    lineHeight: 'body.2',
    fontWeight: 'medium',
  },
  'text-sm': {
    ...baseBtnStyles,
    ...baseTextStyles,
    fontSize: sm.fontSize,
    lineHeight: 'body.1',
    fontWeight: 'medium',
  },
  'text-lg': {
    ...baseBtnStyles,
    ...baseTextStyles,
    fontSize: lg.fontSize,
    lineHeight: 'body.3',
    fontWeight: 'medium',
  },
  'text-icon': {
    ...baseBtnStyles,
    display: 'inline',
    lineHeight: 'inherit',
    bg: 'transparent',
    borderRadius: 0,
    padding: 0,
    margin: 0,
    border: 'none',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    color: 'textGray',
    fill: 'textGray',
    stroke: 'textGray',
    '&:hover': {
      color: 'primaryTint5',
      fill: 'primaryTint5',
      stroke: 'primaryTint5',
    },
    '&:disabled': {
      cursor: 'not-allowed',
      color: 'gray3',
      fill: 'gray3',
      stroke: 'gray3',
      '&:hover': {
        color: 'gray3',
        fill: 'gray3',
        stroke: 'gray3',
      },
    },
  },
}

export const buttonSizeStyles: Record<'sm' | 'md' | 'lg', any> = {
  sm,
  md,
  lg,
}
