import { IncomingMessage } from 'node:http'
import { ApolloClient, NormalizedCacheObject, createHttpLink, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Auth } from 'aws-amplify'
import { log } from 'logger'
// @ts-ignore This path is generated at build time and conflicts otherwise
import { NextApiRequestCookies } from 'next-server/server/api-utils'
import { useMemo } from 'react'
import cache from './apollo-cache'
import { getErrorLink } from './shared/get-error-link'
import { getRetryLink } from './shared/get-retry-link'

let apolloClient: ApolloClient<NormalizedCacheObject>

export type ApolloClientContext = {
  req?: IncomingMessage & {
    cookies: NextApiRequestCookies
  }
  idToken?: string
}

const buildAuthHeaders = (headers: Record<any, any>, idToken: string) => ({
  headers: {
    ...headers,
    authorization: idToken,
  },
})

function createApolloClient(ctx?: ApolloClientContext) {
  const idToken = ctx?.idToken ? `Bearer ${ctx.idToken}` : ''
  const isSSRAuthenticatedSession = typeof window === 'undefined' && idToken
  const httpLink = createHttpLink({
    uri: process.env.NEXT_PUBLIC_GQL_PRIVATE_ENDPOINT,
  })
  const errorLink = getErrorLink()
  const retryLink = getRetryLink()

  const authLink = setContext((_, { headers }) => {
    if (isSSRAuthenticatedSession) return buildAuthHeaders(headers, idToken)

    return Auth.currentSession()
      .then((res) => buildAuthHeaders(headers, res.getIdToken().getJwtToken()))
      .catch((err) => {
        log.error(err)
        return { headers }
      })
  })

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([authLink, errorLink, retryLink, httpLink]),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-and-network',
      },
    },
  })
}

export function initializeApollo(
  initialState?: NormalizedCacheObject | null,
  ctx?: ApolloClientContext
) {
  const _apolloClient = apolloClient ?? createApolloClient(ctx)

  if (initialState) {
    _apolloClient.cache.restore(initialState)
  }

  if (typeof window === 'undefined') return _apolloClient
  apolloClient = apolloClient ?? _apolloClient

  return apolloClient
}

export function useApollo(initialState: NormalizedCacheObject | null, ctx?: ApolloClientContext) {
  const store = useMemo(() => initializeApollo(initialState, ctx), [initialState, ctx])
  return store
}
