import { StyleObject } from 'typ'
import { noSelect, srOnly } from './utils'

export const styles: Record<string, StyleObject> = {
  Container: {
    p: 3,
    maxWidth: 1024,
  },
  root: {
    fontFamily: 'body',
    lineHeight: 'body',
    fontWeight: 'body',
  },
  td: {
    fontFamily: 'body',
    fontWeight: 'normal',
    color: 'text',
    fontSize: 2,
    px: 3,
    py: 2,
    whiteSpace: 'nowrap',
  },
  thead: {
    ...noSelect,
    fontFamily: 'body',
    borderBottom: '1px solid',
    borderColor: 'divider',
    color: 'textGray',
  },
  tbody: {},
  th: {
    fontFamily: 'body',
    textAlign: 'left',
    cursor: 'default',
    px: 3,
    fontWeight: 'medium',
    fontSize: 2,
    whiteSpace: 'nowrap',
  },
  tr: {
    fontFamily: 'body',
    height: '37px',
    cursor: 'default',
  },
  hr: {
    color: 'divider',
  },
  a: {
    fontSize: 'inherit',
    fontFamily: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
    borderRadius: 2,
    outline: 'none',
    '&:link': { color: 'primary' },
    '&:visited': { color: 'primary' },
    '&:hover': { textDecoration: 'underline' },
    '&:active': { textDecoration: 'underline' },
    '&:focus-visible': { boxShadow: 'focusRing' },
  },
  menuItem: {
    position: 'relative',
    listStyle: 'none',
    py: 1,
    pr: 3,
    pl: 2,
    mx: 0,
    borderRadius: '2px',
    outline: 'none',
    cursor: 'default',
  },
  ul: {
    pl: 3,
    m: 0,
  },
  li: {
    '::marker': {
      fontSize: '10px',
    },
  },
  srOnly,
}
