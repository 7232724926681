import { Maybe } from 'typ'
import { isNotNil, isUndefined } from 'typeguards'

export function max(values: Maybe<number>[]): number {
  let max: Maybe<number>
  values.forEach((value) => {
    if (
      isNotNil(value) &&
      // biome-ignore lint/suspicious/noSelfCompare: This works as expected
      ((isNotNil(max) && max < value) || (isUndefined(max) && value >= value))
    ) {
      max = value
    }
  })
  return max ?? 0
}
