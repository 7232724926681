import { Maybe } from 'typ'
import { isNotNil, isUndefined } from 'typeguards'

export function min(values: Maybe<number>[]): number {
  let min: Maybe<number>
  values.forEach((value) => {
    if (
      isNotNil(value) &&
      // biome-ignore lint/suspicious/noSelfCompare: This is a comparison to check if the value is a number
      ((isNotNil(min) && min > value) || (isUndefined(min) && value >= value))
    ) {
      min = value
    }
  })

  return min ?? 0
}
