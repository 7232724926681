import { log } from 'logger'
import Trans from 'next-translate/Trans'
import { toast } from 'ui-v2/components/Toast'
import { hasResourceAlreadyExistsProblem } from './checkers'
import { RESOURCE_ALREADY_EXISTS_KEY } from './message-keys'
import { BaseError, ProblemHandlerOptions } from './types'

/**
 *
 * @param errors A list of ProblemInterfaces
 * @description handles mutation unique check problems with a standard toast message
 */

export const handleResourceAlreadyExistsProblem = (
  errors: BaseError[],
  options: ProblemHandlerOptions
) => {
  if (hasResourceAlreadyExistsProblem(errors)) {
    toast.error(<Trans i18nKey={options?.i18nKey ?? RESOURCE_ALREADY_EXISTS_KEY} />, {
      toastId: options.toastId,
    })
    options?.cb?.()
    log.debug('ResourceAlreadyExistsProblem')
  }
}
