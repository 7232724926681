import clsx from 'clsx'
import { SvgIconProps } from 'icons/types'
import { ComponentType, forwardRef, useId, useState } from 'react'
import {
  mergeProps,
  useButton,
  useFocus,
  useFocusVisible,
  useHover,
  useObjectRef,
} from 'react-aria'
import { ButtonProps as AriaButtonProps } from 'react-aria-components'
import { TestProps } from 'typ'
import { useLocale } from '../../hooks/useLocale'
import { LoaderWrapper } from '../loader'
import { TooltipTrigger, TooltipTriggerProps } from '../tooltip'
import {
  Color,
  Shape,
  Size,
  Variant,
  disabledCx,
  focusVisibleCx,
  hoveredCx,
  pressedCx,
  wrapperCx,
} from './IconButton.css'

export type IconButtonProps = Omit<
  AriaButtonProps,
  'children' | 'form' | 'formAction' | 'formEncType' | 'formMethod' | 'formNoValidate'
> &
  TestProps & {
    color: Color
    size?: Size
    variant: Variant
    shape: Shape
    isLoading?: boolean
    Icon: ComponentType<SvgIconProps>
    label: string
    tabIndex?: number
    tooltipProps?: Partial<Omit<TooltipTriggerProps, 'children'>>
    hidden?: boolean
    isVisible?: boolean
  }

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      color,
      size = 'md',
      variant,
      Icon,
      shape,
      className,
      isDisabled,
      isLoading,
      label,
      onPress,
      tooltipProps,
      isVisible = true,
      ...props
    },
    forwardedRef
  ) => {
    const ref = useObjectRef(forwardedRef)
    const { locale } = useLocale()
    const { isFocusVisible } = useFocusVisible()
    const [isFocused, onFocusChange] = useState(false)
    const { focusProps } = useFocus({ onFocusChange, isDisabled })
    const { hoverProps, isHovered } = useHover({ isDisabled })
    const { buttonProps, isPressed } = useButton({ ...props, onPress }, ref)
    const mergedProps = mergeProps(buttonProps, hoverProps, focusProps, props)

    const backupId = useId()
    const buttonId = mergedProps.id ?? backupId

    const loadingString = locale === 'en' ? 'loading' : 'ロード中'
    const isLoadingAriaLiveLabel = `${label} ${loadingString}`

    const loaderColor = variant === 'solid' ? 'disabledBackground' : 'disabledContent'

    return (
      <TooltipTrigger placement="top" tooltipContent={label} ref={ref} {...tooltipProps}>
        <button
          {...mergedProps}
          id={buttonId}
          className={clsx([
            wrapperCx({ size, color, variant, shape, isVisible }),
            isHovered && hoveredCx({ variant, color }),
            isPressed && pressedCx({ variant, color }),
            isFocusVisible && isFocused && focusVisibleCx({ color }),
            (isDisabled || isLoading) && disabledCx({ variant }),
            className,
          ])}
          data-color={color}
          data-shape={shape}
          data-size={size}
          data-variant={variant}
          aria-disabled={isLoading ? 'true' : undefined}
          aria-label={isLoading ? isLoadingAriaLiveLabel : label}
          disabled={isDisabled || isLoading}
        >
          <LoaderWrapper size={size} color={loaderColor} isLoading={isLoading}>
            <Icon size={size} />
          </LoaderWrapper>
        </button>
      </TooltipTrigger>
    )
  }
)

IconButton.displayName = 'IconButton'
