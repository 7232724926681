import isDeepEqual from 'fast-deep-equal'

/**
 * Extracts the differences between two objects recursively.
 * @param prev The original form state
 * @param curr The updated form state
 * @returns A filtered object containing only the keys that have been subject to a change
 */
export function extractDeepDiff(
  prev?: Record<string, any> | null,
  curr?: Record<string, any> | null
): Record<string, any> {
  if (!prev || !curr || Array.isArray(curr)) return {}

  const diff: Record<string, any> = {}

  Object.entries(curr).forEach(([key, value]) => {
    const prevValue = prev[key]

    if (
      typeof value === 'object' &&
      !Array.isArray(value) &&
      value !== null &&
      prevValue &&
      typeof prevValue === 'object'
    ) {
      const nestedDiff = extractDeepDiff(prevValue, value)
      if (Object.keys(nestedDiff).length > 0) {
        diff[key] = nestedDiff
      }
    } else if (!isDeepEqual(value, prevValue)) {
      diff[key] = value
    }
  })

  return diff
}
