// @ts-nocheck
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { SchemaLink } from '@apollo/client/link/schema'
import { addMocksToSchema } from '@graphql-tools/mock'
import { GqlApiMocks } from 'generated/tensor-api/composite-types'
// @ts-ignore
import introspectionQuery from 'generated/tensor-api/introspection.json'
// @ts-ignore
import possibleTypesQuery from 'generated/tensor-api/possibleTypes.json'
import { buildClientSchema } from 'graphql/utilities'
import { remap } from 'object'

export const createMockTensorApiClient = ({
  mocks = {},
  cache,
}: {
  mocks?: GqlApiMocks | undefined
  cache?: InMemoryCache
}) => {
  // const introspectionQuery = require(`../../generated/tensor-api/introspection.json`)
  // const possibleTypesQuery = require(`../../generated/tensor-api/possibleTypes.json`)
  const possibleTypesResolvers = remap(possibleTypesQuery, () => ({
    __resolveType: (obj: any) => obj?.__typename ?? obj.$ref.typename ?? obj.$ref.typeName,
  }))
  const schema = buildClientSchema(introspectionQuery as any)
  const mockSchema = addMocksToSchema({
    schema,
    resolvers: () => ({
      ...possibleTypesResolvers,
      ...mocks,
    }),
    mocks: {
      Date: () => new Date(),
      Time: () => new Date().toISOString(),
    },
  })

  const defaultCache = new InMemoryCache({ possibleTypes: possibleTypesQuery })

  return new ApolloClient({
    link: new SchemaLink({ schema: mockSchema }),
    cache: cache ?? defaultCache,
    ssrMode: false,
  })
}
