import { DEV_BASE_URL, LOCAL_BASE_URL } from '../tensor-cloud/index'

type App = 'tc-fe' | 'reports'

const getDevBaseUrl = (app: App) => {
  switch (app) {
    case 'tc-fe':
      return DEV_BASE_URL
    case 'reports':
      // TODO: NO DEV ENV READY YET
      return 'http://localhost:3000'
    default:
      return DEV_BASE_URL
  }
}
export const getCurrentBaseUrl = (app: App): string => {
  if (!(process as any)?.browser || typeof window === 'undefined')
    return ['development', 'test'].includes(String(process.env.NODE_ENV))
      ? LOCAL_BASE_URL
      : getDevBaseUrl(app)
  return `${window.location.protocol}//${window.location.host}`
}
