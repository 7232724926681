import { Theme } from 'theme-ui'
import {
  badges,
  breakpoints,
  buttons,
  colors,
  fontSizes,
  fontWeights,
  fonts,
  forms,
  layout,
  lineHeights,
  links,
  noSelect,
  pills,
  radii,
  roundedScrollbars,
  scrollbars,
  shadows,
  sizes,
  space,
  styles,
  text,
  textJa,
  zIndices,
} from './assets'

const makeTheme = <T extends Theme>(t: T) => t

export const themeBase = {
  badges,
  breakpoints,
  buttons,
  colors,
  fontSizes,
  fontWeights,
  fonts,
  forms,
  layout,
  lineHeights,
  links,
  noSelect,
  pills,
  radii,
  rawColors: colors,
  roundedScrollbars,
  scrollbars,
  shadows,
  sizes,
  space,
  styles,
  text,
  zIndices,
}
export const theme = makeTheme(themeBase)
export const makeLocalizedTheme = (lang: string, options: { fontFamily: string }) =>
  makeTheme({
    ...themeBase,
    text: lang === 'ja' ? textJa : text,
    fonts: {
      body: `${options.fontFamily}`,
      heading: `${options.fontFamily}`,
      monospace: `${options.fontFamily}`,
    },
  })
