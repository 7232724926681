import { Maybe } from 'typ'
import { isEmpty, isNil } from 'typeguards'
import { BaseError } from './types'

export const hasAuthorizationProblem = (errors: BaseError[]) =>
  errors.some(
    (err) => err.__typename === 'AccessRightProblem' || err.__typename === 'AuthorizationProblem'
  )

export const hasResourceAlreadyExistsProblem = (errors: BaseError[]) =>
  errors.some((err) => err.__typename === 'ResourceAlreadyExistsProblem')

export const hasResourceDoesNotExistProblem = (errors: BaseError[]) =>
  errors.some((err) => err.__typename === 'ResourceDoesNotExistProblem')

export const onlyHasResourceDoesNotExistProblem = (errors: Maybe<BaseError[]>) =>
  isNil(errors) ||
  isEmpty(errors) ||
  errors.every((err) => err.__typename === 'ResourceDoesNotExistProblem')

export const hasResourceNotAvailableProblem = (errors: BaseError[]) =>
  errors.some(
    (err) =>
      err.__typename === 'ResourceIsAlreadyInUseProblem' ||
      err.__typename === 'ResourceDoesNotExistProblem'
  )

export const hasWorkspaceInvitationProblem = (errors: BaseError[]) =>
  errors.some((err) => err.__typename === 'WorkspaceInvitationIdProblem')
