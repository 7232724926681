import { OperationUploadsAcceptedFile, StoreSlice } from '../types'

export interface MeterDataUploadSlice {
  meterDataAcceptedFiles: OperationUploadsAcceptedFile[]
  setMeterDataAcceptedFiles: (files: OperationUploadsAcceptedFile[]) => void
  addMeterDataAcceptedFiles: (files: OperationUploadsAcceptedFile[]) => void
  removeMeterDataAcceptedFile: (id: string) => void
  resetMeterDataUpload: VoidFunction
}

const initialValues = {
  meterDataAcceptedFiles: [],
}

export const createMeterDataUploadSlice: StoreSlice<MeterDataUploadSlice> = (set, get) => ({
  ...initialValues,
  setMeterDataAcceptedFiles: (files: OperationUploadsAcceptedFile[]) =>
    set({ meterDataAcceptedFiles: files }),
  addMeterDataAcceptedFiles: (files: OperationUploadsAcceptedFile[]) => {
    const existingFiles = get().meterDataAcceptedFiles
    set({ meterDataAcceptedFiles: [...files, ...existingFiles] })
  },
  removeMeterDataAcceptedFile: (id: string) => {
    const files = get().meterDataAcceptedFiles
    set({
      meterDataAcceptedFiles: files.filter((f) => id !== f.data.id),
    })
  },
  resetMeterDataUpload: () => set(initialValues),
})
