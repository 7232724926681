import { isDefined, isNotNil, isUndefined } from 'typeguards'

export function extent<T>(values: T[]) {
  let min: T | undefined
  let max: T | undefined
  values.forEach((value) => {
    if (isNotNil(value)) {
      if (isUndefined(min)) {
        // biome-ignore lint/suspicious/noSelfCompare: <explanation>
        if (value >= value) min = max = value
      } else {
        if (isDefined(min) && min > value) min = value
        if (isDefined(max) && max < value) max = value
      }
    }
  })

  return [min, max]
}
