import type { ErrorEvent, TransactionEvent } from '@sentry/types'
import { isIncluded } from 'typeguards'

type SentryEnvironment = 'production' | 'staging' | 'development'
export const filterEventsByEnv =
  (env: SentryEnvironment | SentryEnvironment[]) =>
  <T extends TransactionEvent | ErrorEvent>(event: T) => {
    if (!event.environment) {
      return null
    }
    if (Array.isArray(env)) {
      return isIncluded(event.environment, env) ? event : null
    }
    return event.environment === env ? event : null
  }
