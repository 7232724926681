/**
 *
 * @param first A first name
 * @param last A ;ast name
 * @param lang The language to format into
 * @returns A formatted first name based on different language conventions
 */
export const formatFullName = (
  first: string | undefined = '',
  last: string | undefined = '',
  lang = 'en'
) => {
  if (lang === 'ja') return `${last} ${first}`
  return `${first} ${last}`.trim()
}
